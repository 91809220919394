import React, { useEffect } from "react";
import { Box, Card } from "@mui/material";

import "./glass-card.scss";
import { Props } from "models/props";

const GlassCard: React.FC<Props> = ({ children }) => {
  
  return (
    <div className={"glass-card"}>
      <div className={"content"}>
        {children}
      </div>
    </div>
  );
};
export { GlassCard };