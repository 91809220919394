import React from 'react';
import { Grid, Typography } from "@mui/material";
import Markdown from "markdown-to-jsx";
import { markdownOptions } from "components/options/markdown-options";

export type SectionParagraphProps = {
    title: string | null;
    content: string;
}

const SectionParagraph: React.FC<SectionParagraphProps> = ({title, content}) => {
    return (
        <Grid container item spacing={1}>
            { title &&
                <Grid item>
                    <Typography variant={"h5"} color={"textPrimary"}>{title}</Typography>
                </Grid>
            }
            <Grid item xs={12}>
                <Markdown
                    options={markdownOptions}
                >
                    {content}
                </Markdown>
            </Grid>
        </Grid>
    )
}
export { SectionParagraph }