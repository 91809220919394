import React from 'react';
import { Box } from "@mui/material";
import { Asset } from "models/schemas/asset";

export type AssetBannerProps = {
    asset: Asset
}

const AssetBanner: React.FC<AssetBannerProps> = ({asset}) => {
    return (
        <Box width={"100%"} position={"absolute"} top={0} left={0} zIndex={-1}>
            <img src={asset.url} width={"100%"} alt={asset.alt} style={{minHeight: "350px", objectFit: "cover"}}/>
            <Box className={"bottom-gradient"} height={"50%"}/>
        </Box>
    )
}
export { AssetBanner }