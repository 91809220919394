import React, { ReactNode } from 'react';
import { Grid, Typography } from "@mui/material";

export type SectionHeaderProps = {
    title: string;
    description?: string | ReactNode;
    gutterTop?: boolean;
}

const SectionHeader: React.FC<SectionHeaderProps> = ({ title, description, gutterTop = false }) => {
    
    return (
        <Grid container item justifyContent={"center"} spacing={1}  mt={gutterTop ? "5.0em": "0.0em"}>
            <Grid item xs={12}>
                <Typography variant={"h4"} color={"textPrimary"} align={"center"}>{title}</Typography>
            </Grid>
            {description &&
                <Grid item xs={12}>
                    {typeof description === "string" ?
                        <Typography variant={"body1"} align={"center"}
                                    color={"textSecondary"}>{description}</Typography> : description
                    }
                </Grid>
            }

        </Grid>
    )
}
export { SectionHeader }