import React from "react";

import { Container, Grid, Typography, useTheme } from "@mui/material";

import { Layout } from "layouts/layout";
import { graphql, HeadFC, HeadProps, PageProps } from "gatsby";
import { Content } from "models/schemas/content";
import { Vacancy } from "models/schemas/vacancy";
import { Asset } from "models/schemas/asset";
import { SectionHeader } from "components/layout/section-header";
import { SectionParagraph } from "components/layout/section-paragraph";
import { GlassCard } from "components/layout/glass-card";
import { NewReleases } from "@mui/icons-material";
import { PageMetadata } from "components/layout/page-metadata";
import { AssetBanner } from "content/banners/asset-banner";
import { Page } from "models/schemas/page";
import { Metadata } from "models/metadata";
import { GraphQlArray } from "models/graphql/graphql-array";

export type CareersProps = Page & {
    page: {
        contents: Content[]
        vacancies: Vacancy[],
        metadata: Metadata,
        banner: Asset
    },
    vacancies: GraphQlArray<Vacancy>
}

const Careers: React.FC<PageProps<CareersProps>> = ({ data }) => {

    const { page, vacancies } = data;
    const introduction = page.contents.filter(c => c.tags.includes("Introduction"))
    const stayConnected = page.contents.filter(c => c.tags.includes("Stay Connected"))

    const theme = useTheme();
    
    return (
        <Layout>
            <Grid container>
                {page.banner &&
                    <AssetBanner asset={page.banner}/>
                }
                <Container maxWidth={'lg'}>
                    <Grid container item spacing={10} justifyContent={"center"}>
                        <Grid item container>
                            <Grid item xs={12}>
                                <SectionHeader
                                    title={"Careers"}
                                    description={"Join Our Team of Innovators"}
                                    gutterTop
                                />
                            </Grid>
                        </Grid>
                        <Grid item xs={12}>
                            {introduction.map((content: Content) => (
                                <SectionParagraph
                                    key={content.heading}
                                    title={content.heading}
                                    content={content.richText.markdown}
                                />
                            ))}
                        </Grid>

                        <Grid item md={8} container justifyContent={"center"}>
                            {vacancies.nodes.map((vacancy: Vacancy) => (
                                <Grid key={vacancy.identifier} item xs={4}>
                                    <GlassCard>
                                        <></>
                                    </GlassCard>
                                </Grid>

                            ))}
                            {vacancies.nodes.length === 0 &&
                                <GlassCard>
                                    <Grid container item justifyContent={"center"} spacing={1}>
                                        <Grid item mt={theme.spacing(1)}>
                                            <NewReleases color={"primary"} fontSize={"large"}/>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Typography align={"center"} variant={"h5"} fontWeight={"bold"}>
                                                Oh no, it's not you, it's us!</Typography>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Typography align={"center"} color={"textSecondary"}>
                                                We don't have any vacancies posted currently,
                                                check below for more information on how to still apply!
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                </GlassCard>
                            }
                        </Grid>

                        <Grid item xs={12}>
                            {stayConnected.map((content: Content) => (
                                <SectionParagraph
                                    key={content.heading}
                                    title={content.heading}
                                    content={content.richText.markdown}
                                />
                            ))}
                        </Grid>
                    </Grid>
                </Container>
            </Grid>
        </Layout>
    )
}
export default Careers;

export const Head: HeadFC = (props: HeadProps<any>) => (
    <PageMetadata location={props.location.pathname} metadata={props.data.page.metadata}/>
)

export const query = graphql`
{
    page: graphCmsPage(identifier: { eq: "careers" }) {
        banner {
            url
            alt
        }
        metadata {
            title
            description
            thumbnail {
              url
            }
            embedImage {
              url
            }
            jsonLD
        }
        contents {
            heading
            richText {
              markdown
            }
            tags
        }
    }
    vacancies: allGraphCmsVacancy {
        nodes {
            identifier
            jobTitle
            location,
            jobType
            jobDescription
            keyResponsibilities
            qualifications
            experience
            salary
            benefits
            instructions
            deadline
        }
    }
}`